import React from "react"
import './TargetCard.scss'
const TargetCard = ({ data }) => {
  return (
    <div className="case_box">
      {
        data?.map(v=> 
        (
          <div className="case_content" >
            <img
              className="case_img"
              src={v.path}
              style={{cursor:"pointer"}}
              onClick={()=>{window.open(v.siteUrl, '_blank');}}
            />
            <div className="case_msg">
                <div className="case_title">
                  <div className="case_title_ti">{v.title}</div>
                  <div className="case_title_tag">
                    {
                      v.tags?.map(v=>( <div className="tags">{v}</div> ))
                    }
                  </div>
                </div>
                <div className="case_introduce">
                  <div className="case_introduce_title">
                    项目介绍
                  </div>
                  <div className="case_introduce_msg">
                    {v.description}
                  </div>
                </div>
                <div className="case_num" style={{display:'none'}}>
                  <div className="case_num_left case_num_content">
                    <div>{v.totalNum}</div>
                    <div className="num_name">总曝光量</div>
                  </div>

                  <div className="case_num_right case_num_content">
                  <div>{v.searchNum}</div>
                  <div className="num_name">品牌词检索量</div>
                  </div>
                </div>
            </div>
          </div>
        )
      )
      }
      
    </div>
  )
}

export default TargetCard
